<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>Archived Categories</h1>
        </v-col>
        <v-col cols="8">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: parentRouteName }">
              <v-icon small left>mdi-arrow-left</v-icon> Back to Categories
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="categories"
        no-data-text="No Archived Categories found"
      >
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    parentRouteName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "News",
          disabled: false,
          to: { name: "module-news" },
          exact: true,
        },
        {
          text: "Categories",
          disabled: false,
          to: { name: "module-news-categories" },
          exact: true,
        },
        {
          text: "Archived",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Title", value: "title" },
        { text: "Slug", value: "slug" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    categories() {
      return this.$store.state.news["archivedNewsCategories"];
    },
  },
};
</script>
